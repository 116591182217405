(function($){

  $(".per-nav").on('click', function(){
    var index = $(this).index();

    $(this).parent().find(".per-nav").removeClass("active");
    $(this).addClass("active");
    $(this).parents(".block-data-per").find(".block-data-per-tab .person-info").removeClass("active");
    $(this).parents(".block-data-per").find(".block-data-per-tab .person-info").eq(index).addClass("active");
  });

})(jQuery)